import React from 'react'
import PropTypes from 'prop-types'
import { inject, observer } from 'mobx-react'
import {
  withStyles,
  CssBaseline,
  AppBar,
  Toolbar,
  Grid,
  Popover,
  Typography,
  Link,
  Divider
} from '@material-ui/core'
import EntryForm from './components/EntryForm'
import Expenses from './components/Expenses'
import Carousel from './components/Carousel'

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 800,
    margin: '0 auto',
    marginBottom: 15
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing.unit * 12,
    marginRight: theme.spacing.unit * 12
  },
  appBar: { position: 'relative' },
  paper: {
    width: '100%',
    padding: '10px 10px 15px 10px'
  },
  popoverText: {
    margin: theme.spacing.unit * 2
  },
  link: {
    color: '#800080',
    cursor: 'pointer'
  },
  hover: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
})

@inject('commonStore')
@observer
class App extends React.Component {
  state = {
    anchorEl: null
  }

  disclaimerClick = e => {
    this.setState({ anchorEl: e.currentTarget })
  }

  disclaimerClose = () => {
    this.setState({ anchorEl: null })
  }

  refresh = () => {
    window.location.reload()
  }

  render() {
    const { classes, commonStore } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <React.Fragment>
        <CssBaseline />
        <AppBar
          position="absolute"
          color="default"
          className={classes.appBar}
          style={{ backgroundColor: '#fff' }}>
          <Toolbar style={{ maxWidth: 800, margin: '0 auto' }}>
          <Typography
            variant="h4"
            gutterBottom
            style={{ textAlign: 'center', color: '#000000' }}>
            CLIFF COUNSELOR
          </Typography>

          </Toolbar>
          <Toolbar style={{ maxWidth: 800, margin: '0 auto' }}>
            <img className={classes.hover} onClick={this.refresh} src="/intapps/benefitstool/images/UMKC_Midwest-Leadership-Center-for-Leadership_template.png" width="218" height="" />
          </Toolbar>
        </AppBar>
        <main className={classes.layout}>
          <Grid container className={classes.root}>
            {/* Main Content */}
            <Grid item xs={12}>
              {commonStore.showSummary ? <Expenses /> : <EntryForm />}
            </Grid>

            <Carousel />

            {/* Disclaimer */}
            <Grid item xs={12}>
              <Divider variant="middle" />
              <Typography
                style={{ fontWeight: 'bold', color: '#800080', textAlign: 'center', marginTop: 15 }}
                aria-owns={open ? 'disclaimer-popover' : undefined}
                aria-haspopup="true"
                variant="button"
                gutterBottom
                onClick={this.disclaimerClick}>
                <Link color="inherit" className={classes.link}>
                  Disclaimer
                </Link>
              </Typography>
              <Popover
                style={{ textAlign: 'center' }}
                id="disclaimer-popover"
                open={open}
                anchorEl={anchorEl}
                onClose={this.disclaimerClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Typography component="p" style={{ fontSize: 12 }} className={classes.popoverText}>
                  {`Information provided on this page is an estimation and not an actual determination of benefits.   We disclaim any representations or warranties as to the accuracy of the estimation of benefits.  You agree to hold us harmless for your use of the calculator/application.  You must consult with the appropriate organizations to determine your specific eligibility for benefits.`}
                </Typography>
              </Popover>
            </Grid>
          </Grid>
        </main>
      </React.Fragment>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(App)
