import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import {
  withStyles,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  Divider,
  TextField,
  MenuItem,
  Tooltip,
  IconButton,
  Button
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'

const styles = theme => ({
  root: {},
  paper: {
    marginTop: theme.spacing.unit * 3,
    width: '100%',
    padding: '10px 10px 20px 10px',
    fontSize: 12,
    marginBottom: 15
  },
  tooltip: {
    fontSize: 12
  },
  btnRoot: {
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  underline: {
    '&:after': {
      borderBottomColor: '#0066cc'
    },
    '&:hover': {
      borderBottomColor: '#0066cc'
    },
    '&:hover:before': {
      borderBottomColor: '#0066cc'
    }
  }
})

@inject('commonStore')
@observer
class EntryForm extends Component {
  handleCurrentChange = name => e => {
    this.props.commonStore.setCurrent(name, e.target.value)
  }

  handleProposedChange = name => e => {
    this.props.commonStore.setProposed(name, e.target.value)
  }

  handleClick = () => {
    this.props.commonStore.getCalculations()
  }

  render() {
    const { classes, commonStore } = this.props

    return (
      <React.Fragment>
        <Paper className={classes.paper}>
          <Grid container spacing={16}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ textAlign: 'center', color: '#0066cc' }}>
                {`Current`}
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                gutterBottom
                style={{ textAlign: 'center', color: '#0066cc' }}>
                {`Proposed`}
              </Typography>
              <Divider />
            </Grid>

            {/* Hours Per Week */}
            <Grid item xs={6}>
              <Typography>{`1) How many hours per week do you work?`}</Typography>
              <TextField
                type="number"
                name="hoursWorkedPerWeek"
                placeholder="0"
                onChange={this.handleCurrentChange('hoursWorkedPerWeek')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`1) Hours per week?`}</Typography>
              <TextField
                type="number"
                name="hoursWorkedPerWeek"
                placeholder="0"
                onChange={this.handleProposedChange('hoursWorkedPerWeek')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>

            <Divider />

            {/* Income Per Hour */}
            <Grid item xs={6}>
              <Typography>{`2) How much do you make per hour?`}</Typography>
              <TextField
                type="number"
                placeholder="0.00"
                name="hourlyWage"
                onChange={this.handleCurrentChange('hourlyWage')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`2) Hourly wage?`}</Typography>
              <TextField
                type="number"
                placeholder="0.00"
                name="hourlyWage"
                onChange={this.handleProposedChange('hourlyWage')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>

            {/* Number in Household */}
            <Grid item xs={6}>
              <Typography>{`3) How many adults & children live in your household?`}</Typography>
              <TextField
                type="number"
                name="numberOfPeopleInHH"
                placeholder="0"
                onChange={this.handleCurrentChange('numberOfPeopleInHH')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`3) Adults & children in your household?`}</Typography>
              <TextField
                type="number"
                name="numberOfPeopleInHH"
                placeholder="0"
                onChange={this.handleProposedChange('numberOfPeopleInHH')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>

            {/* Children in Household */}
            <Grid item xs={6}>
              <Typography>{`4) How many children live in your household?`}</Typography>
              <TextField
                type="number"
                name="numberOfChildrenInHH"
                placeholder="0"
                onChange={this.handleCurrentChange('numberOfChildrenInHH')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`4) Children in your household?`}</Typography>
              <TextField
                type="number"
                name="numberOfChildrenInHH"
                placeholder="0"
                onChange={this.handleProposedChange('numberOfChildrenInHH')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>

            {/* Daycare less than 2 years old */}
            <Grid item xs={6}>
              <Typography>{`5) How many children under the age of 2 attend daycare?`}</Typography>
              <TextField
                type="number"
                name="childrenUnder2InDayCare"
                placeholder="0"
                onChange={this.handleCurrentChange('childrenUnder2InDayCare')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`5) Children under the age of 2 attend daycare?`}</Typography>
              <TextField
                type="number"
                name="childrenUnder2InDayCare"
                placeholder="0"
                onChange={this.handleProposedChange('childrenUnder2InDayCare')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>

            {/* Daycare more than 2 years old */}
            <Grid item xs={6}>
              <Typography>{`6) How many children age 2+ years attend daycare?`}</Typography>
              <TextField
                type="number"
                name="childrenOver1InDayCare"
                placeholder="0"
                onChange={this.handleCurrentChange('childrenOver1InDayCare')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`6) Children age 2+ years attend daycare?`}</Typography>
              <TextField
                type="number"
                name="childrenOver1InDayCare"
                placeholder="0"
                onChange={this.handleProposedChange('childrenOver1InDayCare')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>

            {/* Child care assistance eligibility */}
            <Grid item xs={6}>
              <Typography>{`7) How many in household are eligible for foodstamps?`}</Typography>
              <TextField
                type="number"
                name="numberOfPeopleInHHFoodStamps"
                placeholder="0"
                onChange={this.handleCurrentChange('numberOfPeopleInHHFoodStamps')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`7) How many in household are eligible for foodstamps?`}</Typography>
              <TextField
                type="number"
                name="numberOfPeopleInHHFoodStamps"
                placeholder="0"
                onChange={this.handleProposedChange('numberOfPeopleInHHFoodStamps')}
                InputProps={{ classes: { underline: classes.underline } }}
              />
            </Grid>

            {/* Child care center co-payments */}
            <Grid item xs={6}>
              <Typography>{`8) Does your child care center collect co-payments?`}</Typography>
              <TextField
                select
                onChange={this.handleCurrentChange('childCareCenterCollectsCoPayments')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.current.childCareCenterCollectsCoPayments}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`8) Does your child care center collect co-payments?`}</Typography>
              <TextField
                select
                onChange={this.handleProposedChange('childCareCenterCollectsCoPayments')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.proposed.childCareCenterCollectsCoPayments}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
            </Grid>

            {/* Eligible for differential payments */}
            <Grid item xs={6}>
              <Typography>{`9) Are you eligible for differential payments?`}</Typography>
              <TextField
                select
                onChange={this.handleCurrentChange('eligibleForDifferentialPayment')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.current.eligibleForDifferentialPayment}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              <Tooltip
                title="If the children and/or the parent is disabled, child care provider receives a 25% differential payment. Beginning in September 2016 homeless children will also qualify."
                placement="right"
                classes={{ tooltip: classes.tooltip }}>
                <IconButton classes={{ root: classes.btnRoot }}>
                  <HelpIcon style={{ width: 15, height: 15, color: '#0066cc' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`9) Are you eligible for differential payments?`}</Typography>
              <TextField
                select
                onChange={this.handleProposedChange('eligibleForDifferentialPayment')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.proposed.eligibleForDifferentialPayment}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              <Tooltip
                title="If the children and/or the parent is disabled, child care provider receives a 25% differential payment. Beginning in September 2016 homeless children will also qualify."
                placement="right"
                classes={{ tooltip: classes.tooltip }}>
                <IconButton classes={{ root: classes.btnRoot }}>
                  <HelpIcon style={{ width: 15, height: 15, color: '#0066cc' }} />
                </IconButton>
              </Tooltip>
            </Grid>

            {/* Children in protective custody */}
            <Grid item xs={6}>
              <Typography>{`10) Do you have any children in protective custody?`}</Typography>
              <TextField
                select
                onChange={this.handleCurrentChange('childrenInProtectiveCustody')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.current.childrenInProtectiveCustody}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              <Tooltip
                title="If the children are in state protective custody, child care provider receives a 25% differential payment and cannot charge a co-payment."
                placement="right"
                classes={{ tooltip: classes.tooltip }}>
                <IconButton classes={{ root: classes.btnRoot }}>
                  <HelpIcon style={{ width: 15, height: 15, color: '#0066cc' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`10) Do you have any children in protective custody?`}</Typography>
              <TextField
                select
                onChange={this.handleProposedChange('childrenInProtectiveCustody')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.proposed.childrenInProtectiveCustody}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              <Tooltip
                title="If the children are in state protective custody, child care provider receives a 25% differential payment and cannot charge a co-payment."
                placement="right"
                classes={{ tooltip: classes.tooltip }}>
                <IconButton classes={{ root: classes.btnRoot }}>
                  <HelpIcon style={{ width: 15, height: 15, color: '#0066cc' }} />
                </IconButton>
              </Tooltip>
            </Grid>

            {/* Subsidized housing */}
            <Grid item xs={6}>
              <Typography>{`11) Are you eligible for subsidized housing?`}</Typography>
              <TextField
                select
                onChange={this.handleCurrentChange('subsidizedHousing')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.current.subsidizedHousing}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`11) Are you eligible for subsidized housing?`}</Typography>
              <TextField
                select
                onChange={this.handleProposedChange('subsidizedHousing')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.proposed.subsidizedHousing}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
            </Grid>

            {/* Elderly or disabled head of household */}
            <Grid item xs={6}>
              <Typography>{`12) Is the head of household elderly or disabled?`}</Typography>
              <TextField
                select
                onChange={this.handleCurrentChange('elderlyOrDisabledHOH')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.current.elderlyOrDisabledHOH}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              <Tooltip
                title="Disability is defined as severe, chronic disability of an individual that is expected to be long-continuing or of indefinite duration and is attributable to: a serious mental illness; a physical, mental, or emotional impairment, including an impariment caused by alcohol or drug abuse, post traumatic stress disorder, or brain injury; a developmental disability; or a diagnosis of AIDS or HIV infection."
                placement="right"
                classes={{ tooltip: classes.tooltip }}>
                <IconButton classes={{ root: classes.btnRoot }}>
                  <HelpIcon style={{ width: 15, height: 15, color: '#0066cc' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`12) Is the head of household elderly or disabled?`}</Typography>
              <TextField
                select
                onChange={this.handleProposedChange('elderlyOrDisabledHOH')}
                InputProps={{ classes: { underline: classes.underline } }}
                value={commonStore.proposed.elderlyOrDisabledHOH}>
                <MenuItem key={1} value={true}>
                  Yes
                </MenuItem>
                <MenuItem key={2} value={false}>
                  No
                </MenuItem>
              </TextField>
              <Tooltip
                title="Disability is defined as severe, chronic disability of an individual that is expected to be long-continuing or of indefinite duration and is attributable to: a serious mental illness; a physical, mental, or emotional impairment, including an impariment caused by alcohol or drug abuse, post traumatic stress disorder, or brain injury; a developmental disability; or a diagnosis of AIDS or HIV infection."
                placement="right"
                classes={{ tooltip: classes.tooltip }}>
                <IconButton classes={{ root: classes.btnRoot }}>
                  <HelpIcon style={{ width: 15, height: 15, color: '#0066cc' }} />
                </IconButton>
              </Tooltip>
            </Grid>

            {/* Full market rent */}
            <Grid item xs={6}>
              <Typography>{`13) Choose your approximate fair market rent:`}</Typography>
              <TextField
                select
                onChange={this.handleCurrentChange('fullMarketRent')}
                InputProps={{ classes: { underline: classes.underline }  }}
                value={commonStore.current.fullMarketRent}>
                <MenuItem key={1} value={786.00}>1 bedroom $786</MenuItem>
                <MenuItem key={2} value={953.00}>2 bedroom $953</MenuItem>
                <MenuItem key={3} value={1286.00}>3 bedroom $1,286</MenuItem>
                <MenuItem key={4} value={1457.00}>4 bedroom $1,457</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={6}>
              <Typography>{`13) Choose your approximate fair market rent:`}</Typography>
              <TextField
                select
                onChange={this.handleProposedChange('fullMarketRent')}
                InputProps={{ classes: { underline: classes.underline }  }}
                value={commonStore.proposed.fullMarketRent}>
                <MenuItem key={1} value={786.00}>1 bedroom $786</MenuItem>
                <MenuItem key={2} value={953.00}>2 bedroom $953</MenuItem>
                <MenuItem key={3} value={1286.00}>3 bedroom $1,286</MenuItem>
                <MenuItem key={4} value={1457.00}>4 bedroom $1,457</MenuItem>
              </TextField>
            </Grid>

            {/* Monthly TNAF payment */}
            <Grid item xs={6}>
              <Typography>{`14) How much is your monthly TANF payment?`}</Typography>
              <TextField
                type="number"
                name="monthlyTANFPayment"
                placeholder="0.00"
                onChange={this.handleCurrentChange('monthlyTANFPayment')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`14) How much will your TANF payment be?`}</Typography>
              <TextField
                type="number"
                name="monthlyTANFPayment"
                placeholder="0.00"
                onChange={this.handleProposedChange('monthlyTANFPayment')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>

            {/* Monthly SSI for head of household/children */}
            <Grid item xs={6}>
              <Typography>{`15) How much in SSI benefits do you receive for yourself or your child?`}</Typography>
              <TextField
                type="number"
                name="monthlySSIForHH"
                placeholder="0.00"
                onChange={this.handleCurrentChange('monthlySSIForHH')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`15) How much in SSI benefits will you receive?`}</Typography>
              <TextField
                type="number"
                name="monthlySSIForHH"
                placeholder="0.00"
                onChange={this.handleProposedChange('monthlySSIForHH')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>

            {/* Monthly child support received */}
            <Grid item xs={6}>
              <Typography>
                16) How much child support do you{' '}
                <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>receive</span>{' '}
                monthly?
              </Typography>
              <TextField
                type="number"
                name="monthlyChildSupportReceived"
                placeholder="0.00"
                onChange={this.handleCurrentChange('monthlyChildSupportReceived')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                16) How much child support will you{' '}
                <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>receive</span>{' '}
                monthly?
              </Typography>
              <TextField
                type="number"
                name="monthlyChildSupportReceived"
                placeholder="0.00"
                onChange={this.handleProposedChange('monthlyChildSupportReceived')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>

            {/* Monthly child support paid */}
            <Grid item xs={6}>
              <Typography>
                17) How much child support do you{' '}
                <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>pay</span> monthly?
              </Typography>
              <TextField
                type="number"
                name="monthlyChildSupportPaid"
                placeholder="0.00"
                onChange={this.handleCurrentChange('monthlyChildSupportPaid')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>
                17) How much child support will you{' '}
                <span style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>pay</span> monthly?
              </Typography>
              <TextField
                type="number"
                name="monthlyChildSupportPaid"
                placeholder="0.00"
                onChange={this.handleProposedChange('monthlyChildSupportPaid')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>

            {/* Garnishments / judgements */}
            <Grid item xs={6}>
              <Typography>{`18) Enter your garnishments/judgements.`}</Typography>
              <TextField
                type="number"
                name="garnishmentsOrJudgements"
                placeholder="0.00"
                onChange={this.handleCurrentChange('garnishmentsOrJudgements')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`18) Enter your garnishments/judgements.`}</Typography>
              <TextField
                type="number"
                name="garnishmentsOrJudgements"
                placeholder="0.00"
                onChange={this.handleProposedChange('garnishmentsOrJudgements')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>

            {/* Employment expenses */}
            <Grid item xs={6}>
              <Typography>{`19) Enter your employment expenses.`}</Typography>
              <TextField
                type="number"
                name="employmentExpenses"
                placeholder="0.00"
                onChange={this.handleCurrentChange('employmentExpenses')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography>{`19) Enter your employment expenses.`}</Typography>
              <TextField
                type="number"
                name="employmentExpenses"
                placeholder="0.00"
                onChange={this.handleProposedChange('employmentExpenses')}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  classes: { underline: classes.underline }
                }}
              />
            </Grid>
          </Grid>
        </Paper>
        <Grid container justify="center">
          <Button
            style={{ backgroundColor: '#0066cc', marginBottom: 20 }}
            variant="contained"
            color="primary"
            aria-label="Add"
            onClick={this.handleClick}>
            Calculate
          </Button>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(EntryForm)
