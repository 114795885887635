import { observable, action, computed } from 'mobx'
import request from 'superagent'

class CommonStore {
  @observable showSummary = false

  @observable
  current = {
    hoursWorkedPerWeek: 0,
    hourlyWage: 0,
    numberOfPeopleInHH: 0,
    eligibleForFoodStamps: 0,
    numberOfChildrenInHH: 0,
    childrenUnder2InDayCare: 0,
    childrenOver1InDayCare: 0,
    numberOfPeopleInHHFoodStamps: 0,
    eligibleForChildCareAssistance: false,
    childCareCenterCollectsCoPayments: false,
    eligibleForDifferentialPayment: false,
    childrenInProtectiveCustody: false,
    subsidizedHousing: false,
    elderlyOrDisabledHOH: false,
    fullMarketRent: 786.00,
    monthlyTANFPayment: 0,
    monthlySSIForHH: 0,
    monthlyChildSupportReceived: 0,
    monthlyChildSupportPaid: 0,
    garnishmentsOrJudgements: 0,
    employmentExpenses: 0,
    state: "MO"
  }

  @observable
  proposed = {
    hoursWorkedPerWeek: 0,
    hourlyWage: 0,
    numberOfPeopleInHH: 0,
    eligibleForFoodStamps: 0,
    numberOfChildrenInHH: 0,
    childrenUnder2InDayCare: 0,
    childrenOver1InDayCare: 0,
    numberOfPeopleInHHFoodStamps: 0,
    eligibleForChildCareAssistance: false,
    childCareCenterCollectsCoPayments: false,
    eligibleForDifferentialPayment: false,
    childrenInProtectiveCustody: false,
    subsidizedHousing: false,
    elderlyOrDisabledHOH: false,
    fullMarketRent: 786.00,
    monthlyTANFPayment: 0,
    monthlySSIForHH: 0,
    monthlyChildSupportReceived: 0,
    monthlyChildSupportPaid: 0,
    garnishmentsOrJudgements: 0,
    employmentExpenses: 0,
    state: "MO"
  }

  @observable summaryCurrent = {
    foodStamps: 0,
    childCareAssistanceFromState: 0,
    childCareFromProvider: 0,
    rent: 0,
    food: 0,
    childCareSlidingFee: 0,
    childCareCoPayment: 0,
  }

  @observable summaryProposed = {
    foodStamps: 0,
    childCareAssistanceFromState: 0,
    childCareFromProvider: 0,
    rent: 0,
    food: 0,
    childCareSlidingFee: 0,
    childCareCoPayment: 0,
  }

  @action
  setShowSummary(bool) {
    this.showSummary = bool
  }

  @action
  setCurrent(name, value) {
    this.current = { ...this.current, [name]: value }
  }

  @action
  setProposed(name, value) {
    this.proposed = { ...this.proposed, [name]: value }
  }

  @action
  setSummary(data) {
    this.summary = data
  }

  //Computed Properties
  @computed
  get currentMonthlyGrossIncome() {
    return (
      Math.round(this.current.hoursWorkedPerWeek * this.current.hourlyWage * 52 / 12 * 100) / 100
    )
  }

  @computed
  get proposedMonthlyGrossIncome() {
    return (
      Math.round(this.proposed.hoursWorkedPerWeek * this.proposed.hourlyWage * 52 / 12 * 100) / 100
    )
  }

  @computed
  get currentPayrollDeductions() {
    return Math.round(this.currentMonthlyGrossIncome * 0.1 * 100) / 100
  }

  @computed
  get proposedPayrollDeductions() {
    return Math.round(this.proposedMonthlyGrossIncome * 0.1 * 100) / 100
  }

  @computed
  get currentMonthlyNet() {
    return Math.round((this.currentMonthlyGrossIncome - this.currentPayrollDeductions) * 100) / 100
  }

  @computed
  get proposedMonthlyNet() {
    return Math.round((this.proposedMonthlyGrossIncome - this.proposedPayrollDeductions) * 100) / 100
  }

  @computed
  get currentTotalIncomeBenefits() {
    return Math.round((this.currentMonthlyNet + this.summaryCurrent.foodStamps
      + parseFloat(this.current.monthlyTANFPayment) + parseFloat(this.current.monthlySSIForHH)
      + parseFloat(this.current.monthlyChildSupportReceived) + this.summaryCurrent.childCareAssistanceFromState
      + this.summaryCurrent.childCareFromProvider) * 100) / 100
  }

  @computed
  get proposedTotalIncomeBenefits() {
    return Math.round((this.proposedMonthlyNet + this.summaryProposed.foodStamps
      + parseFloat(this.proposed.monthlyTANFPayment) + parseFloat(this.proposed.monthlySSIForHH)
      + parseFloat(this.proposed.monthlyChildSupportReceived) + this.summaryProposed.childCareAssistanceFromState
      + this.summaryProposed.childCareFromProvider) * 100) / 100
  }

  @computed
  get currentTotalExpenses() {
    return Math.round((parseFloat(this.current.employmentExpenses) + parseFloat(this.current.garnishmentsOrJudgements)
      + this.summaryCurrent.rent + this.summaryCurrent.food
      + this.summaryCurrent.childCareSlidingFee + this.summaryCurrent.childCareCoPayment
      + parseFloat(this.current.monthlyChildSupportPaid)
      + this.summaryCurrent.childCareAssistanceFromState) * 100) / 100
  }

  @computed
  get proposedTotalExpenses() {
    return Math.round((parseFloat(this.proposed.employmentExpenses) + parseFloat(this.proposed.garnishmentsOrJudgements)
      + this.summaryProposed.rent + this.summaryProposed.food
      + this.summaryProposed.childCareSlidingFee + this.summaryProposed.childCareCoPayment
      + parseFloat(this.proposed.monthlyChildSupportPaid)
      + this.summaryProposed.childCareAssistanceFromState) * 100) / 100
  }

  @action
  getCalculations() {
    Promise.resolve().then(() => {
      request
        .post('http://net1.umkc.edu/intapps/benefitstool/api/calculator')
        .set('Content-Type', 'application/json')
        .send(this.current)
        .end((err, res) => {
          let response = res.body
          this.summaryCurrent = { ...response }
        })
    }).then(() => {
      request
        .post('http://net1.umkc.edu/intapps/benefitstool/api/calculator')
        .set('Content-Type', 'application/json')
        .send(this.proposed)
        .end((err, res) => {
          let response = res.body
          this.summaryProposed = { ...response }
        })
    }).then(() => {
      this.setShowSummary(true)
    })
  }
}

export default new CommonStore()
