import React from 'react'
import { Carousel } from 'react-bootstrap'

class ImageCarousel extends React.Component {
  state = {
    index: 0,
    direction: null
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({ index: selectedIndex, direction: e.direction })
  }

  render() {
    const { index, direction } = this.state

    return (
      <div style={{ margin: '0 auto'}}>
        <Carousel
          activeIndex={index}
          direction={direction}
          onSelect={this.handleSelect}
          interval={5000}>
          <Carousel.Item>
            <img src="/intapps/benefitstool/images/testimonials/testimonial-1.png" width="600" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="/intapps/benefitstool/images/testimonials/testimonial-2.png" width="600" />
          </Carousel.Item>
          <Carousel.Item>
            <img src="/intapps/benefitstool/images/testimonials/testimonial-3.png" width="600" />
          </Carousel.Item>
        </Carousel>
      </div>
    )
  }
}

export default ImageCarousel
