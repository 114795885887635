import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import { withStyles, Typography } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Currency from 'react-currency-formatter'

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell)

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
    margin: 'auto',
    padding: '10px 10px 10px 10px',
    marginBottom: 15
  },
  row: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default
    }
  },
  TableHead: {
    backgroundColor: theme.palette.secondary.main
  },
  TextField: {
    width: 50
  },
  error: {
    borderColor: theme.palette.common.red
  }
})

function validate(hoursWorkedPerWeek, proposedHourly) {
  // true means invalid, so our conditions got reversed
  return {
    hoursWorkedPerWeek: hoursWorkedPerWeek < 0,
    proposedHourly: proposedHourly < 0
  }
}

@inject('commonStore')
@observer
class Expenses extends Component {
  state = {
    hourlyWage: 0,
    hoursWorkedPerWeek: 0,
    touched: {
      proposedHourly: false,
      hoursWorkedPerWeek: false
    }
  }

  handleChange = name => e => {
    this.props.commonStore.setProposed(name, e.target.value)
    this.props.commonStore.getCalculations()
  }

  render() {
    const { classes, commonStore } = this.props
    let current = commonStore.current
    let proposed = commonStore.proposed
    const errors = validate(this.state.proposedHourly, this.state.hoursWorkedPerWeek)

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <CustomTableCell style={{ backgroundColor: '#800080' }}>
                <Typography style={{ color: '#fff', fontSize: '14px' }}>
                  Income & Benefits
                </Typography>
              </CustomTableCell>
              <CustomTableCell style={{ backgroundColor: '#800080' }} align="right">
                <Typography style={{ color: '#fff', fontSize: '14px' }}>Current</Typography>
              </CustomTableCell>
              <CustomTableCell style={{ backgroundColor: '#800080' }} align="right">
                <Typography style={{ color: '#fff', fontSize: '14px' }}>Proposed</Typography>
              </CustomTableCell>
              <CustomTableCell style={{ backgroundColor: '#800080' }} align="right">
                <Typography style={{ color: '#fff', fontSize: '14px' }}>Difference</Typography>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.row} key="1">
              <CustomTableCell component="th" scope="row">
                {' '}
                Hours Per Week:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">{current.hoursWorkedPerWeek}</CustomTableCell>
              <CustomTableCell align="right">
                <TextField
                  type="number"
                  onChange={this.handleChange('hoursWorkedPerWeek')}
                  defaultValue={proposed.hoursWorkedPerWeek}
                  InputProps={{
                    classes: { underline: classes.underline }
                  }}
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                {' '}
                {current.hoursWorkedPerWeek - proposed.hoursWorkedPerWeek}{' '}
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="2">
              <CustomTableCell component="th" scope="row">
                {' '}
                Hourly Wage:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                {' '}
                <Currency quantity={parseFloat(current.hourlyWage)} currency="USD" />{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <TextField
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    classes: { underline: classes.underline }
                  }}
                  onChange={this.handleChange('hourlyWage')}
                  defaultValue={proposed.hourlyWage}
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={ proposed.hourlyWage - current.hourlyWage} currency="USD" />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="3">
              <CustomTableCell component="th" scope="row">
                {' '}
                Monthly Gross Income:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.currentMonthlyGrossIncome} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.proposedMonthlyGrossIncome} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.proposedMonthlyGrossIncome - commonStore.currentMonthlyGrossIncome
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="4">
              <CustomTableCell component="th" scope="row">
                {' '}
                Less Monthly Payroll Deductions:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.currentPayrollDeductions} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.proposedPayrollDeductions} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.proposedPayrollDeductions - commonStore.currentPayrollDeductions 
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="5">
              <CustomTableCell component="th" scope="row">
                {' '}
                Monthly Net Earned Income:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.currentMonthlyNet} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.proposedMonthlyNet} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.proposedMonthlyNet - commonStore.currentMonthlyNet}
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              <CustomTableCell colSpan={4} style={{ backgroundColor: '#800080' }}>
                <Typography style={{ color: '#fff', fontSize: '14px' }}>
                  Monthly Public Assistance
                </Typography>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.row} key="6">
              <CustomTableCell component="th" scope="row">
                {' '}
                Food Stamps:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.summaryCurrent.foodStamps} />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.summaryProposed.foodStamps} />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.summaryProposed.foodStamps - commonStore.summaryCurrent.foodStamps 
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="7">
              <CustomTableCell component="th" scope="row">
                {' '}
                Monthly TANF Payment:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(current.monthlyTANFPayment)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(proposed.monthlyTANFPayment)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={proposed.monthlyTANFPayment - current.monthlyTANFPayment}
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="8">
              <CustomTableCell component="th" scope="row">
                {' '}
                Monthly SSI For Household:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(current.monthlySSIForHH)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(proposed.monthlySSIForHH)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={parseFloat(proposed.monthlySSIForHH) - parseFloat(current.monthlySSIForHH)}
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="9">
              <CustomTableCell component="th" scope="row">
                {' '}
                Child Support:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(current.monthlyChildSupportReceived)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(proposed.monthlyChildSupportReceived)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    parseFloat(proposed.monthlyChildSupportReceived) - parseFloat(current.monthlyChildSupportReceived)
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="10">
              <CustomTableCell component="th" scope="row">
                {' '}
                Child Care Assistance From State:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryCurrent.childCareAssistanceFromState}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryProposed.childCareAssistanceFromState}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.summaryProposed.childCareAssistanceFromState - commonStore.summaryCurrent.childCareAssistanceFromState
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="11">
              <CustomTableCell component="th" scope="row">
                {' '}
                Child Assistance From Provider:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryCurrent.childCareFromProvider}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryProposed.childCareFromProvider}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.summaryCurrent.childCareFromProvider -
                    commonStore.summaryProposed.childCareFromProvider
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="12">
              <CustomTableCell component="th" scope="row">
                {' '}
                Total Income Benefits:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.currentTotalIncomeBenefits} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.proposedTotalIncomeBenefits} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.proposedTotalIncomeBenefits - commonStore.currentTotalIncomeBenefits
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              <CustomTableCell colSpan={4} style={{ backgroundColor: '#800080' }}>
                <Typography style={{ color: '#fff', fontSize: '14px' }}>
                  Monthly Expenses
                </Typography>
              </CustomTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.row} key="13">
              <CustomTableCell component="th" scope="row">
                {' '}
                Employment Expenses:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(current.employmentExpenses)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(proposed.employmentExpenses)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={parseFloat(proposed.employmentExpenses) - parseFloat(current.employmentExpenses)}
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="14">
              <CustomTableCell component="th" scope="row">
                {' '}
                Child Support/Garnishments/Judgements:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={parseFloat(current.garnishmentsOrJudgements) + parseFloat(current.monthlyChildSupportPaid)}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={parseFloat(proposed.garnishmentsOrJudgements) + parseFloat(proposed.monthlyChildSupportPaid)}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    current.garnishmentsOrJudgements +
                    current.monthlyChildSupportPaid -
                    (proposed.garnishmentsOrJudgements + proposed.monthlyChildSupportPaid)
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="15">
              <CustomTableCell component="th" scope="row">
                {' '}
                Rent:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.summaryCurrent.rent} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.summaryProposed.rent} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryProposed.rent - commonStore.summaryCurrent.rent}
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="16">
              <CustomTableCell component="th" scope="row">
                {' '}
                Food:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.summaryCurrent.food} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.summaryProposed.food} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryProposed.food - commonStore.summaryCurrent.food}
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="17">
              <CustomTableCell component="th" scope="row">
                {' '}
                Child Care Sliding Fee:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryCurrent.childCareSlidingFee}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryProposed.childCareSlidingFee}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.summaryProposed.childCareSlidingFee -  
                    commonStore.summaryCurrent.childCareSlidingFee
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="18">
              <CustomTableCell component="th" scope="row">
                {' '}
                Child Care Copayment:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={parseFloat(commonStore.summaryCurrent.childCareCoPayment)} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryProposed.childCareCoPayment}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.summaryProposed.childCareCoPayment -
                    commonStore.summaryCurrent.childCareCoPayment
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="19">
              <CustomTableCell component="th" scope="row">
                {' '}
                Child Care Assistance From State:{' '}
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryCurrent.childCareAssistanceFromState}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={commonStore.summaryProposed.childCareAssistanceFromState}
                  currency="USD"
                />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency
                  quantity={
                    commonStore.summaryProposed.childCareAssistanceFromState -
                    commonStore.summaryCurrent.childCareAssistanceFromState
                  }
                  currency="USD"
                />
              </CustomTableCell>
            </TableRow>
            <TableRow className={classes.row} key="20">
              <CustomTableCell component="th"> Total Expenses: </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.currentTotalExpenses} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.proposedTotalExpenses} currency="USD" />
              </CustomTableCell>
              <CustomTableCell align="right">
                <Currency quantity={commonStore.proposedTotalExpenses - commonStore.currentTotalExpenses} currency="USD" />
              </CustomTableCell>
            </TableRow>
          </TableBody>

          <TableHead>
            <TableRow>
              <CustomTableCell style={{ backgroundColor: '#800080' }}>
                <Typography style={{ color: '#fff', fontSize: '14px' }}>
                  Net Monthly Gain/Loss
                </Typography>
              </CustomTableCell>
              <CustomTableCell
                align="right"
                style={{ backgroundColor: '#800080', fontSize: '14px' }}>
                <Currency quantity={commonStore.currentTotalIncomeBenefits - commonStore.currentTotalExpenses} currency="USD" />
              </CustomTableCell>
              <CustomTableCell
                align="right"
                style={{ backgroundColor: '#800080', fontSize: '14px' }}>
                <Currency quantity={commonStore.proposedTotalIncomeBenefits - commonStore.proposedTotalExpenses} currency="USD" />
              </CustomTableCell>
              <CustomTableCell
                align="right"
                style={{ backgroundColor: '#800080', fontSize: '14px' }}>
                <Currency quantity={(commonStore.proposedTotalIncomeBenefits - commonStore.proposedTotalExpenses) - (commonStore.currentTotalIncomeBenefits - commonStore.currentTotalExpenses)} currency="USD" />
              </CustomTableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Paper>
    )
  }
}

Expenses.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Expenses)

